import { SearchResult } from './Types'

export const formatSearch = (search: string): SearchResult => {
  const first = search.charAt(0)
  const arr = search.split(' ')
  // Handle Time Search
  if (
    search.startsWith('from') &&
    search.includes('to') &&
    arr[1].includes(':') &&
    arr[3].includes(':')
  ) {
    // Return a fromatted object with start and end time
    return {
      from: arr[1],
      to: arr[3],
    }
  } else if (first !== first.toLowerCase() && first === first.toUpperCase()) {
    return {
      name: arr[0],
      surname: arr[arr.length - 1],
    }
  }
  return {
    err: 'Incorrect Search Params',
  }
}
