import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { formatSearch } from '../Utils/Functions';
import { SearchResult } from '../Utils/Types';

export const SearchBar = () => {
  const [searchField, setSearchField] = React.useState('');

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    setSearchField(e.currentTarget.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const searchResult: SearchResult = formatSearch(searchField);

    if (searchResult.err) alert(searchResult.err);
    else {
      console.table(searchResult);
      setSearchField('');
    }
  };

  return (
    <form className='search' onSubmit={handleSubmit}>
      <input
        type='text'
        placeholder='search here...'
        onChange={handleChange}
        value={searchField}
        name='search'
        id='search'
      />
      <button type='submit'>
        <FaSearch />
      </button>
    </form>
  );
};
