import React from 'react';
import './App.scss';
import { SearchBar } from './Components/SearchBar';

const App: React.FunctionComponent = () => {
  return (
    <div className='App'>
      <h1>Schedule App</h1>
      <SearchBar />
    </div>
  );
};

export default App;
